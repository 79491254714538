@font-face {
  font-family: CooperHewitt-Light;
  src: url("./assets/fonts/CooperHewitt-Light.otf") format("opentype");
}

@font-face {
  font-family: CooperHewitt;
  font-weight: bold;
  src: url("./assets/fonts/CooperHewitt-Bold.otf") format("opentype");
}

@font-face {
  font-family: CooperHewitt;
  font-weight: 700;
  src: url("./assets/fonts/CooperHewitt-Semibold.otf") format("opentype");
}

@font-face {
  font-family: CooperHewitt;
  font-weight: 500;
  src: url("./assets/fonts/CooperHewitt-Medium.otf") format("opentype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: 'CooperHewitt-Medium';
}



.background{
  background: rgb(115,196,143);
  background: linear-gradient(180deg, rgba(115,196,143,1) 0%, rgba(90,182,231,1) 100%) fixed;
}

.banner{
  background-image: url("./assets/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
}



.h1, h1{
  font-size: 4rem;
}